import React from 'react';
import PropTypes from 'prop-types';

const SocialLinks = ({ className }) => (
  <div className={`social-links ${className || ''}`}>
      <a
        href="https://paras.id/collection/kokumokongz.near"
        rel="noopener noreferrer"
        target="_blank"
      >
      <picture>
        <source srcSet="./images/paras-id.png , ./images/paras-id.png" />
        <img
          className="social-links__item"
          src="./images/paras-id.png"
          alt="paras"
        />
      </picture>
    </a>
    <a
      href="https://discord.com/invite/kokumokongz"
      target="_blank"
      rel="noopener noreferrer"
    >
        <img
          className="social-links__item"
          src="./images/discord.png"
          alt="paras"
        />
    </a>
    <a
      href="https://twitter.com/KokumoKongz"
      rel="noopener noreferrer"
      target="_blank"
    >
        <img
          className="social-links__item"
          src="./images/twitter.png"
          alt="paras"
        />
    </a>
  </div>
);

SocialLinks.propTypes = {
  className: PropTypes.string,
};

SocialLinks.defaultProps = {
  className: '',
};

export default SocialLinks;
